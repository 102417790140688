import React, { useEffect, useState } from "react";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  Button,
  TextField,
  CircularProgress,
  Checkbox,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import { Formik } from "formik";
import { Bot } from "../../assets";
import RemoveIcon from "@mui/icons-material/Remove";
import EditIcon from "@mui/icons-material/Edit";
import Select, { components } from "react-select";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useDispatch, useSelector } from "react-redux";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../../slices/notifier";
import {
  createQuestion,
  insertQuestion,
  updateNextQuestion,
  updateQuestion,
} from "../../slices/question";
import constants from "../../utilities/constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { getDepartments } from "../../slices/operaters";
import EditorComponent from "../EditorComponent";
import { isTranslated } from "../../utilities/isTranslated";
import { isEmptyHTML } from "../../utilities/isEmptyHtml";
const CreateBotQuestion = ({ id, open, handleClose, data }) => {
  const dispatch = useDispatch();
  // const [submittedContent, setSubmittedContent] = useState("");
  const { t } = useTranslation();
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const showSnackbar = (data) => {
    // NOTE:
    // if you want to be able to dispatch a `closeSnackbar` action later on,
    // you SHOULD pass your own `key` in the options. `key` can be any sequence
    // of number or characters, but it has to be unique for a given snackbar.
    enqueueSnackbar({
      message: t(data.message),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: data.variant,
        action: (key) => (
          <Button style={{ color: "#fff" }} onClick={() => closeSnackbar(key)}>
            {t("dismiss me")}
          </Button>
        ),
      },
    });
  };
  const [checkBoxChoices, setCheckBoxChoices] = useState({
    content: "",
    department: null,
  });
  const [edit, setEdit] = useState(null);
  const [editSelect, setEditSelect] = useState(null);
  const [checkboxLabel, setCheckBoxLabel] = useState("");
  const [selectLabel, setSelectLabel] = useState("");
  const { i18n } = useTranslation();
  const [value, setValue] = React.useState("");

  const handleChangeTabs = (_, newValue) => {
    setValue(newValue);
  };

  const { user } = useSelector((state) => state.user);
  const { departments } = useSelector((state) => state.operators);
  const [botChoices, setBotChoices] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [isShowedinMeta, setisShowedinMeta] = useState(false);
  const [notify, setNotify] = useState(false);
  const [translations, setTranslations] = useState([]);
  const [translatedSelectChoices, setTranslatedSelectChoices] = useState([]);
  const [translatedCheckBoxChoices, setTranslatedCheckBoxChoices] = useState(
    []
  );
  const [questionContent, setQuestionContent] = useState("");

  const [selectedValue, setSelectedValue] = useState({
    label: "input",
    value: "input",
  });
  const [selectedLanguage, setSelectedLanguage] = useState({
    label: i18n.language.includes("ar")
      ? t("Arabic")
      : i18n.language.includes("fr")
      ? t("French")
      : t("English"),
    value: i18n.language,
  });

  const [department, setDepartment] = useState(null);
  const [botCheckboxChoices, setBotCheckboxChoices] = useState([]);

  const initialValues = {
    question: "",
    type: "",
    order: "",
    label: "",
    assign: isSelected,
    draft: isDraft,
    notify,
    department: department,
  };

  const UpdateQuestion = {
    question: data?.data?.tradingName,
    type: data?.data?.type === undefined ? "input" : data?.data?.type,
    order: data?.data?.order,
    label: data?.data?.label,
    assign: data?.data?.assign,
    notify: data?.data?.notify,
    draft: data?.data?.draft,
    translations: data?.data?.translations,
  };

  useEffect(() => {
    if (botChoices?.find((el) => el?.department)) {
      setIsSelected(true);
    }
  }, [botChoices]);

  useEffect(() => {
    if (isSelected) {
      setSelectedValue({
        label: "select",
        value: "select",
      });
    }
  }, [isSelected]);

  useEffect(() => {
    let UpdateQuestion = {};
    UpdateQuestion.question =
      data?.data?.tradingName !== undefined && data?.data?.tradingName;
    UpdateQuestion.order = data?.data?.order;
    data?.data?.translations && setTranslations(data?.data?.translations);
  }, [data]);

  useEffect(() => {
    if (data?.data?.tradingName !== undefined) {
      setNotify(data?.data?.notify);
      setIsDraft(data?.data?.draft);
      setisShowedinMeta(data?.data?.displayMeta);
      setSelectedValue({
        label: data?.data?.type === undefined ? "input" : data?.data?.type,
        value: data?.data?.type === undefined ? "input" : data?.data?.type,
      });
      if (data?.data?.type === "checkBox") {
        setBotCheckboxChoices(
          data?.data?.choices.map((choice) => {
            return {
              choice: choice.content,
              department: choice?.department ? choice?.department : undefined,
              id: choice._id,
              label: choice.label,
              next: choice?.next ? choice.next : undefined,
              draft: choice?.draft,
              translations: choice?.translations
                ? choice.translations
                : undefined,
            };
          })
        );
        setTranslatedCheckBoxChoices(
          data?.data?.choices.map((choice) => {
            let translations = [];
            constants.LANGUAGES.forEach((el) =>
              choice.translations?.find((item) => item?.language === el.value)
                ? translations.push(
                    choice.translations?.find(
                      (item) => item?.language === el.value
                    )
                  )
                : translations.push({
                    language: el.value,
                    content: "",
                    label: "",
                  })
            );

            return {
              id: choice?._id,
              translations,
            };
          })
        );
      } else {
        if (data?.data?.type === "select") {
          setBotChoices(
            data?.data?.choices.map((choice) => {
              return {
                choice: choice.content,
                department: choice?.department ? choice?.department : undefined,
                id: choice._id,
                label: choice.label,
                next: choice?.next ? choice.next : undefined,
                draft: choice?.draft,
                translations: choice?.translations
                  ? choice.translations
                  : undefined,
              };
            })
          );
          setTranslatedSelectChoices(
            data?.data?.choices.map((choice) => {
              let translations = [];
              constants.LANGUAGES.forEach((el) =>
                choice.translations?.find((item) => item?.language === el.value)
                  ? translations.push(
                      choice.translations?.find(
                        (item) => item?.language === el.value
                      )
                    )
                  : translations.push({ language: el.value, content: "" })
              );

              return {
                id: choice?._id,
                translations,
              };
            })
          );
        }
      }
    }
  }, [data]);

  const {
    updateStatus,
    status,
    updatenextQuestionStatus,
    insertQuestionStatus,
  } = useSelector((state) => state.question);
  const Option = (props) => {
    return (
      <components.Option {...props} className="select-option">
        <div className="radio-btn-label-wrapper">
          <div className="select-option-label">{props.label}</div>
        </div>
      </components.Option>
    );
  };
  const [choice, setChoice] = useState({ content: "", department: null });

  const handleSelectChange = (e) => {
    setSelectedValue(e);
  };

  const handleSelectLanguage = (e) => {
    setSelectedLanguage(e);
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? (
          <KeyboardArrowUpIcon />
        ) : (
          <KeyboardArrowDownIcon />
        )}
      </components.DropdownIndicator>
    );
  };

  const styles = {
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: "all .2s ease",
      // transform: state.isFocused ? "rotate(180deg)" : null,
    }),
    control: (base, state) => ({
      ...base,
      border: "2px solid #ebebeb",
      borderRadius: "8px",
      height: "50px",
      backgroundColor: "white",
      borderbottom: state.isFocused ? "unset" : "1px solid #ebebeb",
      boxShadow: state.isFocused ? "0px " : "0px",
      "&:hover": {
        ...styles,
      },
    }),
    menu: ({ width, ...css }) => ({
      ...css,
      width: "100%",
      boxShadow: "0px",
      borderRadius: "8px",
      border: "1px solid #ebebeb",
      zIndex: "9999",
    }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: "normal",
      color: "#78787A",
      width: "100%",
      backgroundColor: "white",
      fontSize: "12px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#78787A",
      width: "100%",
      fontSize: "12px",
    }),
  };

  return (
    <Dialog
      open={open}
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
      onClose={() => {
        setBotChoices([]);
        setCheckBoxLabel("");
        edit && setEdit(null);
        setSelectLabel("");
        setSelectedValue({
          label: "input",
          value: "input",
        });
        setBotCheckboxChoices([]);
        setCheckBoxChoices([]);
        setChoice("");
        setIsSelected(false);
        setIsDraft(false);
        setisShowedinMeta(false);
        setNotify(false);
        setDepartment(null);
        setQuestionContent("");
        handleClose(id);
        setValue("");
      }}
      PaperProps={{
        style: { borderRadius: 15, maxWidth: "1500px" },
      }}
    >
      <Formik
        initialValues={
          data?.data?.tradingName !== undefined ? UpdateQuestion : initialValues
        }
        validationSchema={yup.object({
          question: yup.string().trim().required(t("Question Is Required")),
          label:
            data?.update !== true
              ? yup.string().trim().required(t("Question Topic Is Required"))
              : yup
                  .string()
                  .trim()
                  .notRequired(t("Question Topic Is Required")),
          order:
            data?.next !== true &&
            data?.update !== true &&
            yup
              .number()
              .required(t("The Order Of The Question Is Required"))
              .test(
                "max",
                (val) =>
                  val === 2 ||
                  (val === 1 && data?.lastQuestion?.order === undefined)
              ),
        })}
        onSubmit={async (values) => {
          try {
            if (data?.insert === true) {
              dispatch(
                insertQuestion({
                  values: values.question,
                  next: data?.nextQuestion?._id,
                  prev: data?.id,
                  order: values.order,
                  label: values.label,
                  translations,
                  websiteID: user.websiteID,
                  type: selectedValue.value,
                  notify,
                  draft: isDraft,
                  displayMeta: isShowedinMeta,
                  assign:
                    selectedValue.label?.includes("select") &&
                    botChoices?.find((el) => el?.department)
                      ? true
                      : selectedValue.label?.includes("checkBox") &&
                        botCheckboxChoices?.find((el) => el?.department) &&
                        true,
                  choices: selectedValue.label?.includes("checkBox")
                    ? botCheckboxChoices.map((item) => {
                        return {
                          label: item?.label,
                          content: item?.choice,
                          next: item?.next ? item.next : undefined,
                          department: item?.department?.value
                            ? item?.department?.value
                            : item?.department?._id
                            ? item?.department?._id
                            : undefined,
                          translations: item?.translations
                            ? item?.translations
                            : undefined,
                        };
                      })
                    : selectedValue.label?.includes("select")
                    ? botChoices.map((item) => {
                        return {
                          label: item?.label,
                          content: item?.choice,
                          next: item?.next ? item.next : undefined,
                          department: item?.department?.value
                            ? item?.department?.value
                            : item?.department?._id
                            ? item?.department?._id
                            : undefined,
                          translations: item?.translations
                            ? item?.translations
                            : undefined,
                        };
                      })
                    : "",
                })
              ).then((res) => {
                res?.error
                  ? showSnackbar({
                      variant: "error",
                      message: res?.error?.message,
                    })
                  : (() => {
                      showSnackbar({
                        variant: "success",
                        message: t("question created successfully"),
                      });
                      setBotChoices([]);
                      setSelectedValue({
                        label: "input",
                        value: "input",
                      });
                      setCheckBoxChoices([]);
                      setBotCheckboxChoices([]);
                      setCheckBoxLabel("");
                      setIsSelected(false);
                      setIsDraft(false);
                      setisShowedinMeta(false);
                      setNotify(false);
                      setDepartment(null);
                      setSelectLabel("");
                      setQuestionContent("");
                      setValue("");
                      handleClose(id);
                    })();
              });
            } else if (data?.next === true) {
              dispatch(
                updateNextQuestion({
                  content: values.question,
                  websiteID: user.websiteID,
                  label: values.label,
                  id: data?.id,
                  notify,
                  draft: isDraft,
                  displayMeta: isShowedinMeta,
                  assign:
                    (botChoices?.find((el) => el?.department) && true) ||
                    (botCheckboxChoices?.find((el) => el?.department) && true),
                  type: selectedValue.value,
                  choices: selectedValue.label?.includes("checkBox")
                    ? botCheckboxChoices.map((item) => {
                        return {
                          label: item?.label,
                          department: item?.department?.value
                            ? item?.department?.value
                            : item?.department?._id
                            ? item?.department?._id
                            : undefined,
                          content: item?.choice,
                          next: item?.next ? item.next : undefined,
                          draft: item?.draft,
                          translations: item?.translations
                            ? item?.translations
                            : undefined,
                        };
                      })
                    : selectedValue.label?.includes("select")
                    ? botChoices.map((item) => {
                        return {
                          label: item?.label,
                          content: item?.choice,
                          next: item?.next ? item.next : undefined,
                          department: item?.department?.value
                            ? item?.department?.value
                            : item?.department?._id
                            ? item?.department?._id
                            : undefined,
                          draft: item?.draft,
                          translations: item?.translations
                            ? item?.translations
                            : undefined,
                        };
                      })
                    : "",
                })
              ).then((res) => {
                res?.error
                  ? showSnackbar({
                      variant: "error",
                      message: t(res?.error?.message),
                    })
                  : (() => {
                      showSnackbar({
                        variant: "success",
                        message: t("question created successfully"),
                      });
                      handleClose(id);
                      setValue("");
                      setBotChoices([]);
                      setSelectedValue({
                        label: "input",
                        value: "input",
                      });
                      setCheckBoxChoices([]);
                      setBotCheckboxChoices([]);
                      setCheckBoxLabel("");
                      setSelectLabel("");
                    })();
              });
            } else {
              if (data.update === undefined) {
                dispatch(
                  createQuestion({
                    values: values.question,
                    order: values.order,
                    label: values.label,
                    translations,
                    websiteID: user.websiteID,
                    type: selectedValue.value,
                    notify,
                    draft: isDraft,
                    displayMeta: isShowedinMeta,
                    assign:
                      selectedValue.label?.includes("select") &&
                      botChoices?.find((el) => el?.department)
                        ? true
                        : selectedValue.label?.includes("checkBox") &&
                          botCheckboxChoices?.find((el) => el?.department) &&
                          true,
                    choices: selectedValue.label?.includes("checkBox")
                      ? botCheckboxChoices.map((item) => {
                          return {
                            label: item?.label,
                            content: item?.choice,
                            department: item?.department?.value
                              ? item?.department?.value
                              : item?.department?._id
                              ? item?.department?._id
                              : undefined,
                            translations: item?.translations
                              ? item?.translations
                              : undefined,
                          };
                        })
                      : selectedValue.label?.includes("select")
                      ? botChoices.map((item) => {
                          return {
                            label: item?.label,
                            content: item?.choice,
                            department: item?.department?.value
                              ? item?.department?.value
                              : item?.department?._id
                              ? item?.department?._id
                              : undefined,
                            translations: item?.translations
                              ? item?.translations
                              : undefined,
                          };
                        })
                      : "",
                  })
                ).then((res) => {
                  res?.error
                    ? showSnackbar({
                        variant: "error",
                        message: res?.error?.message,
                      })
                    : (() => {
                        showSnackbar({
                          variant: "success",
                          message: t("question created successfully"),
                        });
                        setBotChoices([]);
                        setSelectedValue({
                          label: "input",
                          value: "input",
                        });
                        setCheckBoxChoices([]);
                        setBotCheckboxChoices([]);
                        setCheckBoxLabel("");
                        setIsSelected(false);
                        setIsDraft(false);
                        setisShowedinMeta(false);
                        setNotify(false);
                        setDepartment(null);
                        setSelectLabel("");
                        setQuestionContent("");
                        handleClose(id);
                        setValue("");
                      })();
                });
              } else {
                dispatch(
                  updateQuestion({
                    id: data.data.id,
                    websiteID: user.websiteID,
                    content: values.question,
                    label: values.label,
                    translations,
                    next: (() => {
                      if (data.data.next) {
                        const { _id, ...rest } = data?.data?.next;
                        return rest;
                      } else {
                        return undefined;
                      }
                    })(),
                    notify,
                    draft: isDraft,
                    displayMeta: isShowedinMeta,
                    assign:
                      selectedValue.label?.includes("select") &&
                      botChoices?.find((el) => el?.department)
                        ? true
                        : selectedValue.label?.includes("checkBox") &&
                          botCheckboxChoices?.find((el) => el?.department) &&
                          true,
                    type: selectedValue.label?.includes("checkBox")
                      ? selectedValue.label
                      : selectedValue.label?.includes("select")
                      ? selectedValue.label
                      : selectedValue.label?.includes("message")
                      ? selectedValue.label
                      : "input",
                    choices: selectedValue.label?.includes("checkBox")
                      ? botCheckboxChoices.map((item) => {
                          return {
                            department: item?.department?.value
                              ? item?.department?.value
                              : item?.department?._id
                              ? item?.department?._id
                              : undefined,
                            label: item?.label,
                            content: item?.choice,
                            id: item.id,
                            next: item?.next ? item.next : undefined,
                            draft: item?.draft,
                            translations: item?.translations
                              ? item?.translations
                              : undefined,
                          };
                        })
                      : selectedValue.label?.includes("select")
                      ? botChoices.map((item) => {
                          return {
                            //        label: item?.label,
                            content: item?.choice,
                            department: item?.department?.value
                              ? item?.department?.value
                              : item?.department?._id
                              ? item?.department?._id
                              : undefined,
                            id: item.id,
                            next: item?.next ? item.next : undefined,
                            draft: item?.draft,
                            translations: item?.translations
                              ? item?.translations
                              : undefined,
                          };
                        })
                      : "",
                  })
                ).then((res) => {
                  res?.error
                    ? showSnackbar({
                        variant: "error",
                        message: res?.error?.message,
                      })
                    : (() => {
                        showSnackbar({
                          variant: "success",
                          message: t("question updated successfully"),
                        });
                        setBotChoices([]);
                        setSelectedValue({
                          label: "input",
                          value: "input",
                        });
                        setCheckBoxChoices([]);
                        setBotCheckboxChoices([]);
                        setCheckBoxLabel("");
                        setIsSelected(false);
                        setIsDraft(false);
                        setisShowedinMeta(false);
                        setNotify(false);
                        setDepartment(null);
                        setSelectLabel("");
                        setQuestionContent("");
                        handleClose(id);
                        setValue("");
                      })();
                });
              }
            }
          } catch (err) {}
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <div id="Bot-modal">
              <div className="bot-modal-image-title">
                <LazyLoadImage id="question-bot" src={Bot} alt={"Bot"} />
                <DialogTitle className="assign-operator-title">
                  {t(
                    data?.update === undefined
                      ? t("Create Bot Question")
                      : data?.update === true
                      ? t("Update Bot Question")
                      : data?.next === true && t("Create Bot SubQuestion")
                  )}
                </DialogTitle>
              </div>
              <label htmlFor="create-bot-question-TextField">
                {t("Question")} :
              </label>
              <EditorComponent />
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <div
                  style={{
                    width:
                      data?.next !== true && data.update !== true
                        ? "50%"
                        : "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {" "}
                  <label htmlFor="create-bot-question-TextField">
                    {t("Question Topic")} :
                  </label>
                  <TextField
                    id="create-bot-question-TextField"
                    fullWidth
                    placeholder={t("Enter Your Bot Label")}
                    value={values.label}
                    onChange={handleChange}
                    name="label"
                    error={Boolean(touched.label && errors.label)}
                    helperText={touched.label && errors.label}
                    onBlur={handleBlur}
                    InputProps={{
                      className: "create-bot-question-TextField",
                    }}
                  />
                </div>
                {data?.next !== true && data.update !== true && (
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <label htmlFor="create-bot-question-TextField">
                      {t("Question Order")} :
                    </label>
                    <TextField
                      id="create-bot-question-TextField"
                      placeholder={t("Enter Question Order")}
                      value={values.order}
                      type="number"
                      fullWidth
                      onChange={handleChange}
                      error={Boolean(touched.order && errors.order)}
                      helperText={touched.order && errors.order}
                      onBlur={handleBlur}
                      name="order"
                      InputProps={{
                        className: "create-bot-question-TextField",
                      }}
                    />
                  </div>
                )}
              </div>

              <div
                style={{ display: "flex", gap: "20px", alignItems: "center" }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <label htmlFor="create-bot-question-checkbox">
                    {t("Assign department")} :
                  </label>
                  <Checkbox
                    sx={{
                      width: "fit-content",
                      color: "#c7c7c7",
                      "&.Mui-checked": {
                        color: "#fec423",
                      },
                    }}
                    id="create-bot-question-checkbox"
                    name="checkbox"
                    checked={isSelected}
                    onChange={() => {
                      handleChange;
                      setIsSelected((prev) => !prev);

                      departments?.length == 0 &&
                        dispatch(
                          getDepartments({
                            websiteID: user?.websiteID,
                            page: 1,
                            limit: 0,
                          })
                        );
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
                {isSelected && (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <label htmlFor="create-bot-question-checkbox">
                      {t("Notify operators by email")} :
                    </label>
                    <Checkbox
                      sx={{
                        width: "fit-content",
                        color: "#c7c7c7",
                        "&.Mui-checked": {
                          color: "#fec423",
                        },
                      }}
                      id="create-bot-question-checkbox"
                      name="checkbox"
                      checked={notify}
                      onChange={() => {
                        handleChange;
                        setNotify((prev) => !prev);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </div>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label htmlFor="create-bot-question-checkbox">
                  {t("Draft")} :
                </label>
                <Checkbox
                  sx={{
                    width: "fit-content",
                    color: "#c7c7c7",
                    "&.Mui-checked": {
                      color: "#fec423",
                    },
                  }}
                  id="create-bot-question-checkbox"
                  name="checkbox"
                  checked={isDraft}
                  onChange={() => {
                    handleChange;
                    setIsDraft((prev) => !prev);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label htmlFor="create-bot-question-checkbox">
                  {t("Showed with user information")} :
                </label>
                <Checkbox
                  sx={{
                    width: "fit-content",
                    color: "#c7c7c7",
                    "&.Mui-checked": {
                      color: "#fec423",
                    },
                  }}
                  id="create-bot-question-checkbox"
                  name="checkbox"
                  checked={isShowedinMeta}
                  onChange={() => {
                    handleChange;
                    setisShowedinMeta((prev) => !prev);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <label htmlFor="create-bot-question-TextField">
                  {t("Translate your question :")}
                </label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "100%",
                    }}
                  >
                    <Select
                      value={{ label: selectedLanguage.label }}
                      styles={styles}
                      style={{ height: "150px", width: "100%" }}
                      placeholder={<div>Type to search</div>}
                      onChange={handleSelectLanguage}
                      components={{ Option, DropdownIndicator }}
                      options={constants.LANGUAGES.map((language) => {
                        return { ...language, label: t(language.label) };
                      })}
                    />

                    <EditorComponent
                      questionContent={questionContent}
                      setQuestionContent={setQuestionContent}
                    />
                  </div>
                  <AddCircleIcon
                    style={{ color: "#fec423", cursor: "pointer" }}
                    onClick={() => {
                      if (
                        isEmptyHTML(questionContent) ||
                        isTranslated(translations, selectedLanguage.value)
                      ) {
                        showSnackbar({
                          variant: "error",
                          message:
                            questionContent?.trim().length === 0
                              ? t(
                                  "Question content or language must not be empty"
                                )
                              : ` ${t(
                                  `The question is already translated to`
                                )} ${t(selectedLanguage.label)}`,
                        });
                      } else {
                        setTranslations((prev) =>
                          prev.concat({
                            id: Math.random(),
                            content: questionContent,
                            language: selectedLanguage.value,
                          })
                        );
                      }
                    }}
                  />
                </div>
              </div>
              {translations?.length > 0 &&
                translations?.map((item) => {
                  return (
                    <div className="choices-wrapper">
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <span
                          dangerouslySetInnerHTML={{ __html: item.content }}
                        />
                      </div>
                      {item?.language && (
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                          }}
                        >
                          {item?.language}
                        </div>
                      )}

                      <RemoveIcon
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() =>
                          setTranslations((prev) =>
                            prev.filter((el) => el.id !== item.id)
                          )
                        }
                      />
                    </div>
                  );
                })}

              {(data?.next === null ||
                data?.data?.next === null ||
                data.isEntryGate === true ||
                data.update.toString() === "true") && (
                <>
                  <label>{t("Question Type")} :</label>
                  <Select
                    value={{ label: selectedValue.label }}
                    styles={styles}
                    style={{ height: "150px", width: "100%" }}
                    placeholder={<div>Type to search</div>}
                    onChange={handleSelectChange}
                    components={{ Option, DropdownIndicator }}
                    options={
                      isSelected
                        ? constants.BOTOPTIONS?.filter((el) =>
                            ["select", "checkbox"]?.includes(
                              el?.label?.toLocaleLowerCase()
                            )
                          )
                        : constants.BOTOPTIONS
                    }
                  />
                </>
              )}

              {selectedValue.label?.includes("select") &&
                selectedValue.label?.includes("select") !== undefined && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "25px",
                    }}
                  >
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs value={value} onChange={handleChangeTabs}>
                        <Tab value={""} label={"Default"} />

                        {constants.LANGUAGES.map((el, index) => (
                          <Tab value={el.value} label={el.label} key={index} />
                        ))}
                      </Tabs>
                    </Box>

                    <div className="add-choice">
                      {!editSelect && value === "" && (
                        <TextField
                          id="create-bot-question-TextField"
                          placeholder={t("Enter Select choices")}
                          name="choice"
                          value={choice?.content}
                          fullWidth
                          onChange={(e) =>
                            setChoice({ content: e.target.value })
                          }
                          error={Boolean(touched.choice && errors.choice)}
                          helperText={touched.choice && errors.choice}
                          onBlur={handleBlur}
                          InputProps={{
                            className: "create-bot-question-TextField",
                          }}
                        />
                      )}

                      {isSelected && (
                        <Select
                          value={{
                            label: department?.label || "Select a department",
                          }}
                          styles={styles}
                          style={{ height: "150px", width: "100%" }}
                          required
                          placeholder={<div>Type to search</div>}
                          onChange={(selectedOption) => {
                            handleSelectChange;
                            setDepartment(selectedOption);
                            setChoice((prev) => ({
                              ...prev,
                              department: selectedOption,
                            }));
                            setFieldValue("department", selectedOption?.value);
                          }}
                          components={{ Option, DropdownIndicator }}
                          options={departments.map((department) => ({
                            label: department.name,
                            value: department._id,
                          }))}
                        />
                      )}
                      {!editSelect && value === "" && (
                        <AddCircleIcon
                          style={{ color: "#FEC423" }}
                          onClick={() => {
                            if (
                              choice?.content?.trim()?.length === 0
                              // || selectLabel.length === 0
                            ) {
                              showSnackbar({
                                variant: "error",
                                message: t("Choice or label must not be empty"),
                              });
                            } else {
                              let translations = [];
                              const _id = Math.random();
                              setBotChoices((prev) =>
                                prev.concat({
                                  id: _id,
                                  choice: choice?.content,
                                  department: choice?.department,
                                  label: selectLabel,
                                  translations: [],
                                })
                              );
                              constants.LANGUAGES.forEach((el) =>
                                translations.push({
                                  language: el.value,
                                  content: "",
                                })
                              );
                              setTranslatedSelectChoices((prev) => [
                                ...prev,
                                { id: _id, translations },
                              ]);
                            }
                            setChoice({ content: "" });
                            setDepartment(null);
                            setSelectLabel("");
                          }}
                        />
                      )}
                    </div>
                  </div>
                )}
              {selectedValue.label?.includes("checkBox") &&
                selectedValue.label?.includes("checkBox") !== undefined && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "25px",
                    }}
                  >
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs value={value} onChange={handleChangeTabs}>
                        <Tab value={""} label={"Default"} />

                        {constants.LANGUAGES.map((el, index) => (
                          <Tab value={el.value} label={el.label} key={index} />
                        ))}
                      </Tabs>
                    </Box>

                    <div className="add-choice">
                      {!edit && value === "" && (
                        <>
                          <TextField
                            id="create-bot-question-TextField"
                            placeholder={t("Enter Select choices Label")}
                            name="choiceLabel"
                            value={checkboxLabel}
                            fullWidth
                            onChange={(e) => setCheckBoxLabel(e.target.value)}
                            error={Boolean(touched.choice && errors.choice)}
                            helperText={touched.choice && errors.choice}
                            onBlur={handleBlur}
                            InputProps={{
                              className: "create-bot-question-TextField",
                            }}
                          />
                          <TextField
                            id="create-bot-question-TextField"
                            placeholder={t("Enter Select choices")}
                            name="choice"
                            value={checkBoxChoices?.content}
                            fullWidth
                            onChange={(e) =>
                              setCheckBoxChoices({ content: e.target.value })
                            }
                            error={Boolean(touched.choice && errors.choice)}
                            helperText={touched.choice && errors.choice}
                            onBlur={handleBlur}
                            InputProps={{
                              className: "create-bot-question-TextField",
                            }}
                          />
                        </>
                      )}

                      {isSelected && (
                        <Select
                          value={{
                            label: department?.label || "Select a department",
                          }}
                          styles={styles}
                          style={{ height: "150px", width: "100%" }}
                          required
                          placeholder={<div>Type to search</div>}
                          onChange={(selectedOption) => {
                            handleSelectChange;
                            setFieldValue("department", selectedOption?.value);
                            setDepartment(selectedOption);
                            setCheckBoxChoices((prev) => ({
                              ...prev,
                              department: selectedOption,
                            }));
                          }}
                          components={{ Option, DropdownIndicator }}
                          options={departments.map((department) => ({
                            label: department.name,
                            value: department._id,
                          }))}
                        />
                      )}
                      {!edit && value === "" && (
                        <AddCircleIcon
                          style={{ color: "#fec423" }}
                          onClick={() => {
                            if (
                              checkBoxChoices?.content?.length === 0 ||
                              checkboxLabel?.trim()?.length === 0
                            ) {
                              showSnackbar({
                                variant: "error",
                                message: t("Choice or label must not be empty"),
                              });
                            } else {
                              let translations = [];
                              const _id = Math.random();
                              setBotCheckboxChoices((prev) =>
                                prev.concat({
                                  id: _id,
                                  choice: checkBoxChoices?.content,
                                  department: checkBoxChoices?.department
                                    ? checkBoxChoices?.department
                                    : undefined,
                                  label: checkboxLabel,
                                  translations: [],
                                })
                              );
                              constants.LANGUAGES.forEach((el) =>
                                translations.push({
                                  language: el.value,
                                  content: "",
                                  label: "",
                                })
                              );
                              setTranslatedCheckBoxChoices((prev) => [
                                ...prev,
                                { id: _id, translations },
                              ]);
                            }
                            setCheckBoxChoices({ content: "" });
                            setCheckBoxLabel("");
                            setDepartment(null);
                          }}
                        />
                      )}
                    </div>
                  </div>
                )}

              {selectedValue.label?.includes("select") &&
                selectedValue.label?.includes("select") !== undefined &&
                botChoices?.map((item, index) => {
                  return (
                    <div className="choices-wrapper">
                      {editSelect === item.id && value === "" ? (
                        <TextField
                          id="create-bot-question-TextField"
                          placeholder={t("Enter Select choices")}
                          name="choice"
                          value={choice?.content}
                          fullWidth
                          onChange={(e) =>
                            setChoice({ content: e.target.value })
                          }
                          error={Boolean(touched.choice && errors.choice)}
                          helperText={touched.choice && errors.choice}
                          onBlur={handleBlur}
                          InputProps={{
                            className: "create-bot-question-TextField",
                          }}
                        />
                      ) : value === "" ||
                        (botChoices
                          .find((el) => el.id === item.id)
                          ?.translations?.find(
                            (el) => el?.language === value
                          ) &&
                          editSelect !== item.id) ? (
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <span className="choices-label">{t("Choice")}</span>
                          <span
                            className="choice-text"
                            dangerouslySetInnerHTML={{
                              __html:
                                value === ""
                                  ? item.choice
                                  : botChoices
                                      .find((el) => el.id === item.id)
                                      ?.translations?.find(
                                        (el) => el?.language === value
                                      )?.content,
                            }}
                          />
                        </div>
                      ) : (
                        <TextField
                          id="create-bot-question-TextField"
                          placeholder={t(
                            `Translate the choice number ${index + 1}`
                          )}
                          name="choice"
                          value={
                            translatedSelectChoices
                              ?.find((el) => el.id === item.id)
                              ?.translations?.find(
                                (selectChoice) =>
                                  selectChoice?.language === value
                              )?.content
                          }
                          fullWidth
                          onChange={(e) => {
                            setTranslatedSelectChoices((prev) =>
                              prev.map((el) =>
                                el.id === item.id
                                  ? {
                                      ...el,
                                      translations: el?.translations?.find(
                                        (el) => el.language === value
                                      )
                                        ? el?.translations?.map((i) =>
                                            i?.language === value
                                              ? {
                                                  ...i,
                                                  content: e.target.value,
                                                }
                                              : i
                                          )
                                        : [
                                            ...el?.translations,
                                            {
                                              content: e.target.value,
                                              language: value,
                                            },
                                          ],
                                    }
                                  : el
                              )
                            );
                          }}
                          InputProps={{
                            className: "create-bot-question-TextField",
                          }}
                        />
                      )}
                      {item?.department && (
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                          }}
                        >
                          <span className="choices-label">
                            {t("Department")}
                          </span>{" "}
                          <Select
                            value={{
                              label:
                                item?.department?.name ||
                                item?.department?.label ||
                                department?.label ||
                                "Select a department",
                            }}
                            styles={styles}
                            style={{ height: "150px", width: "100%" }}
                            required
                            placeholder={<div>Type to search</div>}
                            onChange={(selectedOption) => {
                              handleSelectChange;
                              setFieldValue(
                                "department",
                                selectedOption?.value
                              );
                              setBotChoices((prev) => {
                                const updatedChoices = prev.map((el) =>
                                  el?.id === item?.id
                                    ? { ...el, department: selectedOption }
                                    : el
                                );
                                return updatedChoices;
                              });
                            }}
                            components={{ Option, DropdownIndicator }}
                            options={departments.map((department) => ({
                              label: department.name,
                              value: department._id,
                            }))}
                          />
                        </div>
                      )}

                      <div
                        style={{
                          display: "flex",
                          gap: "15px",
                          justifyContent: "center",
                        }}
                      >
                        {value !== "" &&
                        !botChoices
                          .find((el) => el.id === item.id)
                          ?.translations?.find(
                            (el) => el?.language === value
                          ) ? (
                          <AddCircleIcon
                            style={{ color: "#FEC423", cursor: "pointer" }}
                            onClick={() => {
                              if (
                                translatedSelectChoices
                                  ?.find((el) => el.id === item.id)
                                  ?.translations?.find(
                                    (selectChoice) =>
                                      selectChoice?.language === value
                                  )
                                  ?.content?.trim()?.length === 0
                                // || selectLabel.length === 0
                              ) {
                                showSnackbar({
                                  variant: "error",
                                  message: t(
                                    "Choice or label must not be empty"
                                  ),
                                });
                              } else {
                                setBotChoices((prev) => {
                                  return prev.map((el) =>
                                    el.id === item.id
                                      ? {
                                          ...el,
                                          translations: [
                                            ...el?.translations,
                                            translatedSelectChoices
                                              ?.find((el) => el.id === item.id)
                                              ?.translations?.find(
                                                (selectChoice) =>
                                                  selectChoice?.language ===
                                                  value
                                              ),
                                          ],
                                        }
                                      : el
                                  );
                                });
                              }
                            }}
                          />
                        ) : (
                          <>
                            {editSelect === item.id ? (
                              <ClearOutlinedIcon
                                onClick={() => {
                                  setEditSelect(null);
                                  setChoice({ content: "", department: null });
                                }}
                                style={{ color: "#757575", cursor: "pointer" }}
                              />
                            ) : (
                              <EditIcon
                                style={{ color: "#757575", cursor: "pointer" }}
                                onClick={() => {
                                  setEditSelect(item.id);
                                  value === ""
                                    ? setChoice({ content: item.choice })
                                    : setTranslatedSelectChoices((prev) =>
                                        prev.map((el) =>
                                          el.id === item.id
                                            ? {
                                                ...el,
                                                translations:
                                                  el.translations.map((i) =>
                                                    i?.language === value
                                                      ? botChoices
                                                          ?.find(
                                                            (el) =>
                                                              el.id === item.id
                                                          )
                                                          ?.translations?.find(
                                                            (selectChoice) =>
                                                              selectChoice?.language ===
                                                              value
                                                          )
                                                      : i
                                                  ),
                                              }
                                            : el
                                        )
                                      );
                                }}
                              />
                            )}
                            {editSelect === item.id ? (
                              <CheckOutlinedIcon
                                style={{ color: "#7AC600", cursor: "pointer" }}
                                onClick={() => {
                                  if (
                                    value === ""
                                      ? choice?.content?.trim()?.length === 0
                                      : translatedSelectChoices
                                          ?.find((el) => el.id === item.id)
                                          ?.translations?.find(
                                            (selectChoice) =>
                                              selectChoice?.language === value
                                          )
                                          ?.content?.trim()?.length === 0
                                  ) {
                                    showSnackbar({
                                      variant: "error",
                                      message: t(
                                        "Choice or label must not be empty"
                                      ),
                                    });
                                  } else {
                                    value === ""
                                      ? setBotChoices((prev) =>
                                          prev.map((el) =>
                                            el.id === editSelect
                                              ? {
                                                  id: Math.random(),
                                                  choice: choice?.content,
                                                  department:
                                                    choice?.department,
                                                  label: selectLabel,
                                                  next: el?.next
                                                    ? el?.next
                                                    : undefined,
                                                  translations:
                                                    el?.translations,
                                                  draft: el?.draft
                                                    ? el?.draft
                                                    : undefined,
                                                }
                                              : el
                                          )
                                        )
                                      : setBotChoices((prev) =>
                                          prev.map((el) =>
                                            el.id === item.id
                                              ? {
                                                  ...el,
                                                  translations:
                                                    el?.translations?.map((i) =>
                                                      i?.language === value
                                                        ? translatedSelectChoices
                                                            ?.find(
                                                              (el) =>
                                                                el.id ===
                                                                item.id
                                                            )
                                                            ?.translations?.find(
                                                              (selectChoice) =>
                                                                selectChoice?.language ===
                                                                value
                                                            )
                                                        : i
                                                    ),
                                                }
                                              : el
                                          )
                                        );
                                    setEditSelect(null);
                                  }
                                  setChoice({ content: "" });
                                  setDepartment(null);
                                  setSelectLabel("");
                                }}
                              />
                            ) : (
                              <RemoveIcon
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={() =>
                                  value === ""
                                    ? setBotChoices((prev) =>
                                        prev.filter((el) => el.id !== item.id)
                                      )
                                    : (setBotChoices((prev) =>
                                        prev.map((el) =>
                                          el.id === item.id
                                            ? {
                                                ...el,
                                                translations:
                                                  el?.translations?.filter(
                                                    (i) => i?.language !== value
                                                  ),
                                              }
                                            : el
                                        )
                                      ),
                                      setTranslatedSelectChoices((prev) =>
                                        prev.map((el) =>
                                          el.id === item.id
                                            ? {
                                                ...el,
                                                translations:
                                                  el?.translations?.map((i) =>
                                                    i?.language === value
                                                      ? { ...i, content: "" }
                                                      : i
                                                  ),
                                              }
                                            : el
                                        )
                                      ))
                                }
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}

              {selectedValue.label?.includes("checkBox") &&
                selectedValue.label?.includes("checkBox") !== undefined &&
                botCheckboxChoices.map((item, index) => {
                  return (
                    <div className="choices-wrapper">
                      {edit === item.id && value === "" ? (
                        <>
                          <TextField
                            id="create-bot-question-TextField"
                            placeholder={t("Enter Select choices Label")}
                            name="choiceLabel"
                            value={checkboxLabel}
                            fullWidth
                            onChange={(e) => setCheckBoxLabel(e.target.value)}
                            error={Boolean(touched.choice && errors.choice)}
                            helperText={touched.choice && errors.choice}
                            onBlur={handleBlur}
                            InputProps={{
                              className: "create-bot-question-TextField",
                            }}
                          />
                          <TextField
                            id="create-bot-question-TextField"
                            placeholder={t("Enter Select choices")}
                            name="choice"
                            value={checkBoxChoices?.content}
                            fullWidth
                            onChange={(e) =>
                              setCheckBoxChoices({ content: e.target.value })
                            }
                            error={Boolean(touched.choice && errors.choice)}
                            helperText={touched.choice && errors.choice}
                            onBlur={handleBlur}
                            InputProps={{
                              className: "create-bot-question-TextField",
                            }}
                          />
                        </>
                      ) : value === "" ||
                        (botCheckboxChoices
                          .find((el) => el.id === item.id)
                          ?.translations?.find(
                            (el) => el?.language === value
                          ) &&
                          edit !== item.id) ? (
                        <>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "15px",
                            }}
                          >
                            <span className="choices-label">{t("Label")}</span>
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  value === ""
                                    ? item.label
                                    : botCheckboxChoices
                                        .find((el) => el.id === item.id)
                                        ?.translations?.find(
                                          (el) => el?.language === value
                                        )?.label,
                              }}
                            />
                          </div>
                          <div className="remove-choice">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "15px",
                              }}
                            >
                              <span className="choices-label">
                                {t("Choice")}
                              </span>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html:
                                    value === ""
                                      ? item.choice
                                      : botCheckboxChoices
                                          .find((el) => el.id === item.id)
                                          ?.translations?.find(
                                            (el) => el?.language === value
                                          )?.content,
                                }}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <TextField
                            id="create-bot-question-TextField"
                            placeholder={t(
                              `Translate the choice label number ${index + 1}`
                            )}
                            name="choiceLabel"
                            value={
                              translatedCheckBoxChoices
                                ?.find((el) => el.id === item.id)
                                ?.translations?.find(
                                  (selectChoice) =>
                                    selectChoice?.language === value
                                )?.label
                            }
                            fullWidth
                            onChange={(e) =>
                              setTranslatedCheckBoxChoices((prev) =>
                                prev.map((el) =>
                                  el.id === item.id
                                    ? {
                                        ...el,
                                        translations: el?.translations?.find(
                                          (el) => el.language === value
                                        )
                                          ? el?.translations?.map((i) =>
                                              i?.language === value
                                                ? {
                                                    ...i,
                                                    label: e.target.value,
                                                  }
                                                : i
                                            )
                                          : [
                                              ...el?.translations,
                                              {
                                                label: e.target.value,
                                                language: value,
                                              },
                                            ],
                                      }
                                    : el
                                )
                              )
                            }
                            InputProps={{
                              className: "create-bot-question-TextField",
                            }}
                          />
                          <TextField
                            id="create-bot-question-TextField"
                            placeholder={t(
                              `Translate your choice number ${index + 1}`
                            )}
                            name="choice"
                            value={
                              translatedCheckBoxChoices
                                ?.find((el) => el.id === item.id)
                                ?.translations?.find(
                                  (selectChoice) =>
                                    selectChoice?.language === value
                                )?.content
                            }
                            fullWidth
                            onChange={(e) =>
                              setTranslatedCheckBoxChoices((prev) =>
                                prev.map((el) =>
                                  el.id === item.id
                                    ? {
                                        ...el,
                                        translations: el?.translations?.find(
                                          (el) => el.language === value
                                        )
                                          ? el?.translations?.map((i) =>
                                              i?.language === value
                                                ? {
                                                    ...i,
                                                    content: e.target.value,
                                                  }
                                                : i
                                            )
                                          : [
                                              ...el?.translations,
                                              {
                                                content: e.target.value,
                                                language: value,
                                              },
                                            ],
                                      }
                                    : el
                                )
                              )
                            }
                            InputProps={{
                              className: "create-bot-question-TextField",
                            }}
                          />
                        </>
                      )}

                      {item?.department && (
                        <div className="department-choice-container">
                          <span className="choices-label">
                            {t("Department")}
                          </span>

                          <Select
                            value={{
                              label:
                                item?.department?.name ||
                                item?.department?.label ||
                                "Select a department",
                            }}
                            styles={styles}
                            style={{ height: "150px", width: "100%" }}
                            required
                            placeholder={<div>Type to search</div>}
                            onChange={(selectedOption) => {
                              handleSelectChange;
                              setBotCheckboxChoices((prev) => {
                                const updatedChoices = prev?.map((el) =>
                                  el?.id === item?.id
                                    ? { ...el, department: selectedOption }
                                    : el
                                );
                                return updatedChoices;
                              });
                            }}
                            components={{ Option, DropdownIndicator }}
                            options={departments.map((department) => ({
                              label: department.name,
                              value: department._id,
                            }))}
                          />
                        </div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "15px",
                        }}
                      >
                        {value !== "" &&
                        !botCheckboxChoices
                          .find((el) => el.id === item.id)
                          ?.translations?.find(
                            (el) => el?.language === value
                          ) ? (
                          <AddCircleIcon
                            style={{ color: "#FEC423", cursor: "pointer" }}
                            onClick={() => {
                              if (
                                translatedCheckBoxChoices
                                  ?.find((el) => el.id === item.id)
                                  ?.translations?.find(
                                    (selectChoice) =>
                                      selectChoice?.language === value
                                  )
                                  ?.content?.trim()?.length === 0 ||
                                translatedCheckBoxChoices
                                  ?.find((el) => el.id === item.id)
                                  ?.translations?.find(
                                    (selectChoice) =>
                                      selectChoice?.language === value
                                  )
                                  ?.label?.trim()?.length === 0
                              ) {
                                showSnackbar({
                                  variant: "error",
                                  message: t(
                                    "Choice or label must not be empty"
                                  ),
                                });
                              } else {
                                setBotCheckboxChoices((prev) =>
                                  prev.map((el) =>
                                    el.id === item.id
                                      ? {
                                          ...el,
                                          translations: [
                                            ...el?.translations,
                                            translatedCheckBoxChoices
                                              ?.find((el) => el.id === item.id)
                                              ?.translations?.find(
                                                (selectChoice) =>
                                                  selectChoice?.language ===
                                                  value
                                              ),
                                          ],
                                        }
                                      : el
                                  )
                                );
                              }
                            }}
                          />
                        ) : (
                          <>
                            {edit === item.id ? (
                              <ClearOutlinedIcon
                                onClick={() => {
                                  setEdit(null);
                                  setCheckBoxChoices({ content: "" });
                                  setCheckBoxLabel("");
                                }}
                                style={{ color: "#757575", cursor: "pointer" }}
                              />
                            ) : (
                              <EditIcon
                                style={{ color: "#757575", cursor: "pointer" }}
                                onClick={() => {
                                  setEdit(item.id);
                                  value === ""
                                    ? (setCheckBoxLabel(item.label),
                                      setCheckBoxChoices({
                                        content: item.choice,
                                      }))
                                    : setTranslatedCheckBoxChoices((prev) =>
                                        prev.map((el) =>
                                          el.id === item.id
                                            ? {
                                                ...el,
                                                translations:
                                                  el.translations.map((i) =>
                                                    i?.language === value
                                                      ? botCheckboxChoices
                                                          ?.find(
                                                            (el) =>
                                                              el.id === item.id
                                                          )
                                                          ?.translations?.find(
                                                            (selectChoice) =>
                                                              selectChoice?.language ===
                                                              value
                                                          )
                                                      : i
                                                  ),
                                              }
                                            : el
                                        )
                                      );
                                }}
                              />
                            )}

                            {edit === item.id ? (
                              <CheckOutlinedIcon
                                style={{ color: "#7AC600", cursor: "pointer" }}
                                onClick={() => {
                                  if (
                                    value === ""
                                      ? checkBoxChoices?.content?.length ===
                                          0 ||
                                        checkboxLabel?.trim()?.length === 0
                                      : translatedCheckBoxChoices
                                          ?.find((el) => el.id === item.id)
                                          ?.translations?.find(
                                            (selectChoice) =>
                                              selectChoice?.language === value
                                          )
                                          ?.content?.trim()?.length === 0 ||
                                        translatedCheckBoxChoices
                                          ?.find((el) => el.id === item.id)
                                          ?.translations?.find(
                                            (selectChoice) =>
                                              selectChoice?.language === value
                                          )
                                          ?.label?.trim()?.length === 0
                                  ) {
                                    showSnackbar({
                                      variant: "error",
                                      message: t(
                                        "Choice or label must not be empty"
                                      ),
                                    });
                                  } else {
                                    value === ""
                                      ? setBotCheckboxChoices((prev) => {
                                          return prev.map((el) =>
                                            el.id === edit
                                              ? {
                                                  id: Math.random(),
                                                  choice:
                                                    checkBoxChoices?.content,
                                                  department:
                                                    checkBoxChoices?.department
                                                      ? checkBoxChoices?.department
                                                      : undefined,
                                                  label: checkboxLabel,
                                                  next: el?.next
                                                    ? el?.next
                                                    : undefined,
                                                  translations:
                                                    el?.translations,
                                                  draft: el?.draft
                                                    ? el?.draft
                                                    : undefined,
                                                }
                                              : el
                                          );
                                        })
                                      : setBotCheckboxChoices((prev) =>
                                          prev.map((el) =>
                                            el.id === item.id
                                              ? {
                                                  ...el,
                                                  translations:
                                                    el?.translations?.map((i) =>
                                                      i?.language === value
                                                        ? translatedCheckBoxChoices
                                                            ?.find(
                                                              (el) =>
                                                                el.id ===
                                                                item.id
                                                            )
                                                            ?.translations?.find(
                                                              (selectChoice) =>
                                                                selectChoice?.language ===
                                                                value
                                                            )
                                                        : i
                                                    ),
                                                }
                                              : el
                                          )
                                        );
                                    setEdit(null);
                                  }
                                  setCheckBoxChoices({ content: "" });
                                  setCheckBoxLabel("");
                                  setDepartment(null);
                                }}
                              />
                            ) : (
                              <RemoveIcon
                                style={{
                                  color: !edit && "red",
                                  cursor: "pointer",
                                  pointerEvents: edit && "none",
                                }}
                                onClick={() =>
                                  value === ""
                                    ? setBotCheckboxChoices((prev) =>
                                        prev.filter((el) => el.id !== item.id)
                                      )
                                    : (setBotCheckboxChoices((prev) =>
                                        prev.map((el) =>
                                          el.id === item.id
                                            ? {
                                                ...el,
                                                translations:
                                                  el?.translations?.filter(
                                                    (i) => i?.language !== value
                                                  ),
                                              }
                                            : el
                                        )
                                      ),
                                      setTranslatedCheckBoxChoices((prev) =>
                                        prev.map((el) =>
                                          el.id === item.id
                                            ? {
                                                ...el,
                                                translations:
                                                  el?.translations?.map((i) =>
                                                    i?.language === value
                                                      ? {
                                                          ...i,
                                                          content: "",
                                                          label: "",
                                                        }
                                                      : i
                                                  ),
                                              }
                                            : el
                                        )
                                      ))
                                }
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
            <DialogActions className="assign-operator-btns">
              <Button
                id="cancel-create-bot-question"
                onClick={() => {
                  setBotChoices([]);
                  setCheckBoxChoices([]);
                  setCheckBoxLabel("");
                  setSelectLabel("");
                  edit && setEdit(null);
                  setSelectedValue({
                    label: "input",
                    value: "input",
                  });
                  setBotCheckboxChoices([]);
                  setChoice("");
                  handleClose(id);
                  setDepartment(null);
                  setIsSelected(false);
                  setIsDraft(false);
                  setisShowedinMeta(false);
                  setValue("");
                }}
              >
                {t("Cancel")}
              </Button>
              <Button
                id="add-question-bot-btn"
                disabled={
                  (status === "loading" && data?.update === undefined) ||
                  (insertQuestionStatus === "loading" &&
                    data?.update === undefined) ||
                  (updatenextQuestionStatus === "loading" && data?.next) ||
                  (data?.update !== undefined && updateStatus === "loading") ||
                  (selectedValue.label?.includes("checkBox") &&
                    botCheckboxChoices?.length === 0) ||
                  (selectedValue.label?.includes("select") &&
                    botChoices?.length === 0)
                }
                className={
                  (status === "loading" && data?.update === undefined) ||
                  (insertQuestionStatus === "loading" &&
                    data?.update === undefined) ||
                  (updatenextQuestionStatus === "loading" && data?.next) ||
                  (data?.update !== undefined && updateStatus === "loading") ||
                  (selectedValue.label?.includes("checkBox") &&
                    botCheckboxChoices?.length === 0) ||
                  (selectedValue.label?.includes("select") &&
                    botChoices?.length === 0)
                    ? "diabled-bot-btn"
                    : ""
                }
                color="primary"
                type="submit"
              >
                {data?.update === undefined &&
                insertQuestionStatus === "loading" ? (
                  <CircularProgress
                    style={{
                      width: "25px",
                      height: "25px",
                      color: "#fff",
                    }}
                  />
                ) : data?.update === undefined && status === "loading" ? (
                  <CircularProgress
                    style={{
                      width: "25px",
                      height: "25px",
                      color: "#fff",
                    }}
                  />
                ) : data?.update !== undefined && updateStatus === "loading" ? (
                  <CircularProgress
                    style={{
                      width: "25px",
                      height: "25px",
                      color: "#fff",
                    }}
                  />
                ) : updatenextQuestionStatus === "loading" && data?.next ? (
                  <CircularProgress
                    style={{
                      width: "25px",
                      height: "25px",
                      color: "#fff",
                    }}
                  />
                ) : data?.update === undefined ? (
                  t(data?.insert ? "Insert" : "Create")
                ) : (
                  t("Edit")
                )}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
export default CreateBotQuestion;
